import React, { useEffect } from "react";
import IContentCard from "./types";
import { ContentCardWrapper, ContentCardImageWrapper } from "./styles";
import Typography from "../../typography/Typography/Typography";
import { GatsbyImage } from "gatsby-plugin-image";
import { BrandBorder, Colors } from "../../../global-styles";
import Button from "../Button/Button";
import CopyIcon from "../../assets/images/vector/copy.svg";
import ShareIcon from "../../assets/images/vector/share.svg";
import { Link } from "gatsby";

const ContentCard = ({
    id,
    title,
    subtitle,
    text,
    source,
    category,
    slug,
    images,
    alts,
    keywords,
}: IContentCard) => {
    console.log(images);
    useEffect(() => {
        console.log(images);
        console.log(category);
    }, [images]);
    return (
        <Link to={slug}>
            <ContentCardWrapper category={category}>
                <Typography as="h2">{title}</Typography>
                {images[0] !== null &&
                    images.map((image, idx) => (
                        <ContentCardImageWrapper key={idx}>
                            <GatsbyImage
                                image={image.childImageSharp.gatsbyImageData}
                                alt={alts[idx]}
                            />
                        </ContentCardImageWrapper>
                    ))}
                <Typography as="h3">{subtitle}</Typography>
                <Typography as="p">{text}</Typography>
                <Typography as="p">źródło: {source}</Typography>
                <BrandBorder />
                <div className="content-card__buttons-wrapper">
                    <Button
                        iconColor={Colors._orange02}
                        Icon={ShareIcon}
                        iconWidth="20px"
                        iconHeight="20px"
                    >
                        udostępnij
                    </Button>
                    <Button
                        iconColor={Colors._orange02}
                        Icon={CopyIcon}
                        iconWidth="20px"
                        iconHeight="20px"
                    >
                        kopiuj link
                    </Button>
                </div>
            </ContentCardWrapper>
        </Link>
    );
};

export default ContentCard;
